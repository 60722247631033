import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import Loader from "../../../shared/loader/Loader";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  API_SERVER_ENDPOINT,
  BASE_URL_ONESITE,
} from "../../../shared/api/ConstantesEndpoints";
import { useTranslation } from "react-i18next";
import ToastError from "../../../shared/toast/ToastError";

const ReouvertureStripeFormulaire = ({
  abonnementIdBdd,
  selectedPriceId,
  promoCode,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const customerId = profil?.customerId;

  if (!stripe || !elements) {
    return "";
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedPriceId) {
      handleError(t("reouvertureStripeFormulaire.selectSubscription"));
      return;
    }

    try {
      setLoading(true);

      if (!stripe) {
        setLoading(false);
        return;
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        setLoading(false);
        return;
      }

      const resultReouvrirAbonnement = await handleReouvrirAbonnement(
        selectedPriceId,
        customerId,
        promoCode
      );

      let nouvelAbonnementId = null;
      let clientSecret = null;

      if (resultReouvrirAbonnement.success) {
        ({ nouvelAbonnementId, clientSecret } = resultReouvrirAbonnement.data);
      }

      sessionStorage.setItem("fromDemandeInstallation", "true");

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${BASE_URL_ONESITE}/confirmation-reouverture?abonnementIdBdd=${encodeURIComponent(
            abonnementIdBdd
          )}&nouvelAbonnementId=${encodeURIComponent(nouvelAbonnementId)}`,
        },
      });

      if (error) {
        await handlePaymentFailure(nouvelAbonnementId, customerId);
        const errorMessage = error.message || "An unknown error occurred";
        throw new Error(errorMessage);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    setLoading(false);
    const errorMessage = error.message ? error.message : error;
    setError(errorMessage);
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  async function handleReouvrirAbonnement(
    selectedPriceId,
    customerId,
    promoCode
  ) {
    try {
      setLoading(true);

      const response = await axios.post(
        `${API_SERVER_ENDPOINT}/abonnements/reactiver`,
        {
          priceId: selectedPriceId,
          customerId,
          promoCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "incomplete") {
        return { success: true, data: response.data };
      } else {
        handleError(t("reouvertureStripeFormulaire.unknownStatus"));
        return {
          success: false,
        };
      }
    } catch (error) {
      handleError(error.message);
    }
  }

  const handlePaymentFailure = async (abonnementId, customerId) => {
    try {
      await axios.post(
        `${API_SERVER_ENDPOINT}/abonnements/supprimer`,
        { abonnementId, customerId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Erreur lors de la suppression de l'abonnement :", error);
      handleError(error);
    }
  };

  const paymentElementOptions = {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PaymentElement className="mb-3" options={paymentElementOptions} />
        <AddressElement
          options={{ mode: "billing", autocomplete: { mode: "disabled" } }}
        />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 4 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            sx={{
              width: "300px",
              height: "60px",
              bgcolor: "#2a1881",
              color: "white",
              fontSize: "18px",
              fontFamily: "Inter Variable",
              fontWeight: "500",
              textTransform: "none",
              boxShadow: "none",
              borderRadius: "10px",
              "&:hover": {
                bgcolor: "#462655",
                color: "white",
                boxShadow: "none",
              },
            }}
            disabled={!stripe || !elements}
          >
            {t("reouvertureStripeFormulaire.confirmOrder")}
          </LoadingButton>
        </Box>
      </form>
      {loading && <Loader loading={loading} />}
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
};

export default ReouvertureStripeFormulaire;
