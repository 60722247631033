import { useState } from "react";
import FormInformations from "../../components/informations/FormInformations";
import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../shared/loader/LoadingScreen";

const Informations = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [loadingBackground, setLoadingBackground] = useState(true);

  const { abonnementId, siteNom } = useParams();

  const updateCurrentStep = (step) => {
    setCurrentStep(step);
  };

  const steps = [
    t("informations.steps.shopName"),
    t("informations.steps.productImport"),
    t("informations.steps.sector"),
    t("informations.steps.description"),
  ];

  const handleBackgroundLoad = () => {
    setLoadingBackground(false);
  };

  return (
    <>
      {loadingBackground && <LoadingScreen />}
      <div
        style={{
          display: loadingBackground ? "none" : "block",
          height: "100vh",
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/background/one-site-bg.png"}
          alt="Background"
          onLoad={handleBackgroundLoad}
          style={{ display: "none" }}
        />
        <div
          style={{
            height: "100vh",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/background/one-site-bg.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: "1rem",
            }}
          >
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/logo/onesite.png"}
                alt="Informations"
                className="logo-info"
                style={{ height: "80px", width: "auto" }}
              />
            </Link>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                maxWidth: "1200px",
                mb: "2rem",
                px: { xs: "1rem", sm: "2rem" },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  pt: { xs: "2rem", sm: "1rem" },
                  mb: "5%",
                  mt: "1%",
                }}
              >
                <Stepper activeStep={currentStep - 1} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>
                        <Typography
                          sx={{
                            color: "white",
                            fontFamily: '"Space Grotesk", sans-serif',
                            fontWeight: 600,
                            display: { xs: "none", xl: "block" },
                          }}
                        >
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "80%", sm: "50%", md: "40%", xl: "40rem" },
                backgroundColor: "white",
                borderRadius: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: { xs: "100%", md: "100%" },
                boxShadow: "0 0 30px 0 rgba(0, 150, 255, 0.2)",
                padding: "2rem",
              }}
            >
              <FormInformations
                currentStep={currentStep}
                updateCurrentStep={updateCurrentStep}
                abonnementId={abonnementId}
                siteNom={siteNom}
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Informations;
