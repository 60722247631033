import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../../shared/popup/ConfirmationDialog";
import { API_SERVER_ENDPOINT } from "../../../shared/api/ConstantesEndpoints";
import OperationStatusChecker from "../../../shared/operation/OperationStatusChecker";
import AbonnementCard from "./AbonnementCard";
import AbonnementTable from "./AbonnementTable";
import { useTranslation } from "react-i18next";
import Loader from "../../../shared/loader/Loader";
import ToastError from "../../../shared/toast/ToastError";

const DetailsAbonnement = () => {
  const { t } = useTranslation();

  const profil = JSON.parse(sessionStorage.getItem("profil"));
  const [abonnements, setAbonnements] = useState([]);
  const [customerId] = useState(profil?.customerId);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [abonnementIdAnnule, setAbonnementIdAnnule] = useState("");
  const [operationIdInstallation, setOperationIdInstallation] = useState(
    sessionStorage.getItem("operationIdInstallation")
  );
  const [operationIdDomaine, setOperationIdDomaine] = useState(
    sessionStorage.getItem("operationIdDomaine")
  );
  const [operationIdReouverture, setOperationIdReouverture] = useState(
    sessionStorage.getItem("operationIdReouverture")
  );
  const [selectedSite, setSelectedSite] = useState(null);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);

  const navigate = useNavigate();

  const handleError = useCallback((message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 3000);
  }, []);

  const getSubscriptions = useCallback(async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${API_SERVER_ENDPOINT}/utilisateur/abonnements`,
        {
          params: { customerId },
          headers: { "Content-Type": "application/json" },
        }
      );

      const sortedAbonnements = response.data.abonnements.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setAbonnements(sortedAbonnements);
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoading(false);
    }
  }, [customerId, handleError]);

  const handleAnnulerAbonnement = useCallback(
    async (abonnementId, customerId) => {
      try {
        setLoading(true);

        await axios.post(
          `${API_SERVER_ENDPOINT}/abonnements/annuler`,
          { abonnementId, customerId },
          { headers: { "Content-Type": "application/json" } }
        );

        getSubscriptions();
      } catch (error) {
        handleError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [getSubscriptions, handleError]
  );

  const requestAnnulation = (abonnementId) => {
    setAbonnementIdAnnule(abonnementId);
    setShowConfirmDialog(true);
  };

  const handleConfirmAnnulation = useCallback(async () => {
    setShowConfirmDialog(false);
    handleAnnulerAbonnement(abonnementIdAnnule, customerId);
  }, [abonnementIdAnnule, customerId, handleAnnulerAbonnement]);

  const handleReouvrirAbonnement = (abonnementId) => {
    sessionStorage.setItem("authorizedForPayment", "true");

    const abonnement = abonnements.find(
      (ab) => ab.abonnementId === abonnementId
    );

    navigate("/reouverture-abonnement", {
      state: {
        abonnementIdBdd: abonnement.abonnementId,
        nomSite: abonnement.sites[0].nom,
      },
    });
  };

  const supprimerSite = async (siteNom) => {
    try {
      setLoading(true);

      await axios.delete(`${API_SERVER_ENDPOINT}/delete-site`, {
        data: { username: profil.customerName, siteName: siteNom },
      });

      getSubscriptions();
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const requestDeleteSite = (abonnementId, siteNom) => {
    setSelectedSite({ abonnementId, siteNom });
    setShowDeleteConfirmDialog(true);
  };

  const handleConfirmDeleteSite = async () => {
    if (selectedSite) {
      await supprimerSite(selectedSite.abonnementId, selectedSite.siteNom);
    }
    setShowDeleteConfirmDialog(false);
  };

  useEffect(() => {
    if (customerId) {
      getSubscriptions();
    }
  }, [customerId, getSubscriptions]);

  const generateInvoice = async (abonnementId) => {
    setLoading(true);

    const endpoint = `${API_SERVER_ENDPOINT}/abonnements/generate-invoice`;

    try {
      const response = await axios.post(
        endpoint,
        { subscriptionId: abonnementId },
        { responseType: "blob", withCredentials: true }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Invoice-${abonnementId}.pdf`);
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const rerollSite = async (abonnementId, siteNom) => {
    sessionStorage.setItem("authorizedForReroll", "true");

    navigate(`/informations/${abonnementId}/${siteNom}`);
  };

  const ajouterSite = async (abonnementId) => {
    sessionStorage.setItem("authorizedForAjouterSite", "true");

    navigate(`/informations/${abonnementId}`);
  };

  const handleCompleteChecker = useCallback(
    (type) => {
      if (type === "installation") {
        setOperationIdInstallation(null);
      } else if (type === "domainUpdate") {
        setOperationIdDomaine(null);
      } else if (type === "reouverture") {
        setOperationIdReouverture(null);
      }
      getSubscriptions();
    },
    [getSubscriptions]
  );

  const handleErrorStatusChecker = useCallback(
    (type) => {
      if (type === "installation") {
        handleError(t("error.installation"));
        setOperationIdInstallation(null);
      } else if (type === "domainUpdate") {
        handleError(t("error.domainUpdate"));
        setOperationIdDomaine(null);
      } else if (type === "reouverture") {
        handleError(t("error.reouverture"));
        setOperationIdReouverture(null);
      }
      getSubscriptions();
    },
    [handleError, getSubscriptions, t]
  );

  const redirectionCreerSite = () => navigate("/informations");

  return (
    <div
      style={{
        flexGrow: 1,
        padding: "2%",
        backgroundColor: "#f5f8fa",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          margin: "0px",
          overflowX: "auto",
          boxShadow: "none",
          backgroundColor: "#f5f8fa",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "26px", sm: "60px" },
              fontFamily: "Space Grotesk, sans serif",
              fontWeight: "1000",
              letterSpacing: { xs: "-2px", sm: "-5px", md: "-5px", lg: "-5px" },
              ml: "2%",
              mt: { xs: "5%", sm: 0 },
            }}
          >
            {t("abonnement.abonnements")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={redirectionCreerSite}
            sx={{
              background: "#0042ff",
              color: "white",
              fontSize: {
                lg: "14px",
                md: "14px",
                sm: "12px",
                xs: "12px",
              },
              fontFamily: "Inter Variable",
              fontWeight: "500",
              letterSpacing: "-0.25px",
              textTransform: "none",
              mr: 2,
              borderRadius: "10px",
              border: "1px solid #276CFA",
              outline: "1px solid #0646C6",
              "&:hover": {
                backgroundColor: "#1459e2",
                boxShadow: "none",
              },
            }}
          >
            {t("abonnement.creerUnSite")}
            <AddIcon sx={{ fontSize: "20px", ml: "3px" }} />
          </Button>
        </div>
        <Typography
          sx={{
            color: "black",
            fontSize: "20px",
            fontFamily: "Inter Variable",
            fontWeight: "500",
            letterSpacing: "-1.5px",
            mb: "3%",
            mt: "0%",
            ml: "2%",
          }}
        >
          {t("abonnement.afficherGererTraiterAbonnements")}
        </Typography>
        <Box
          marginTop="2rem"
          sx={{
            borderRadius: "20px",
            boxShadow: {
              md: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
              xs: "none",
            },
            width: { xl: "90%", lg: "90%", md: "90%", sm: "95%", xs: "95%" },
            mb: "1%",
            ml: "3%",
            border: "1px solid #E3E3E3",
          }}
        >
          {isMobile ? (
            abonnements.map((abonnement) => (
              <AbonnementCard
                key={abonnement.abonnementId}
                abonnement={abonnement}
                requestAnnulation={requestAnnulation}
                handleReouvrirAbonnement={handleReouvrirAbonnement}
                generateInvoice={generateInvoice}
                rerollSite={rerollSite}
                ajouterSite={ajouterSite}
                supprimerSite={requestDeleteSite}
              />
            ))
          ) : (
            <AbonnementTable
              abonnements={abonnements}
              requestAnnulation={requestAnnulation}
              handleReouvrirAbonnement={handleReouvrirAbonnement}
              generateInvoice={generateInvoice}
              rerollSite={rerollSite}
              customerId={customerId}
              ajouterSite={ajouterSite}
              supprimerSite={requestDeleteSite}
            />
          )}
        </Box>
      </Paper>
      <ConfirmationDialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirmAnnulation}
        message={t("abonnement.confirmAnnulationMessage")}
      />
      <ConfirmationDialog
        open={showDeleteConfirmDialog}
        onClose={() => setShowDeleteConfirmDialog(false)}
        onConfirm={handleConfirmDeleteSite}
        message={t("abonnement.confirmDeleteSiteMessage")}
      />
      {operationIdInstallation && (
        <OperationStatusChecker
          operationId={operationIdInstallation}
          type="installation"
          onComplete={handleCompleteChecker}
          onError={handleErrorStatusChecker}
        />
      )}
      {operationIdDomaine && (
        <OperationStatusChecker
          operationId={operationIdDomaine}
          type="domainUpdate"
          onComplete={handleCompleteChecker}
          onError={handleErrorStatusChecker}
        />
      )}
      {operationIdReouverture && (
        <OperationStatusChecker
          operationId={operationIdReouverture}
          type="reouverture"
          onComplete={handleCompleteChecker}
          onError={handleErrorStatusChecker}
        />
      )}
      <ToastError
        open={error !== null}
        onClose={() => setError(null)}
        message={error}
      />
      <Loader loading={loading}></Loader>
    </div>
  );
};

export default DetailsAbonnement;
